<template>
  <v-container class="container--fluid text-center">
    <Card>
      <v-card-title class="">
        <span class="blue--text text-h6"> Veri Kaynagı Ekle </span>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <div class="col-12 d-flex">
          <Button
            class=""
            :outlined="true"
            :dense="true"
            color="indigo"
            @click="createDevice"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Cihaz Ekle
          </Button>
          <div class="col-2 mt-0 pt-0">
            <Select
              v-model="santralModel2"
              :items="santralList"
              label="Santral Listesi"
              :dense="true"
              :outlined="true"
              :hide-details="true"
              @click="getSantralList"
            />
          </div>
        </div>

        <v-data-table
          :headers="headers"
          :items="devdesserts"
          :items-per-page.sync="itemPerPage"
          class="elevation-1"
          dense
        >
          <template v-slot:item.status="{item}">
            <Chips
              :payload="{
                color: item.status === 'Active' ? 'green' : 'red',
                text: item.status === 'Active' ? 'Aktif' : 'Pasif',
                textColor: 'white',
                small: true
              }"
            />
          </template>
          <template v-slot:item.santralCode="{item}">
            <v-icon
              class="mr-2"
              @click="editItemOpen(item)"
            >
              mdi-pencil-circle
            </v-icon>
            <v-icon
              @click="deleteItem(item)"
            >
              mdi-delete-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </Card>

    <Dialog
      v-model="dialog"
      :persistent="true"
      width="1000px"
    >
      <Card
        width="1000px"
        height="600px"
        :elevation="0"
        class="col-12   pt-0 pb-0"
      >
        <v-card-title class="mr-0">
          <span class="blue--text">
            <h2>Veri Kaynagı Ekle</h2>
          </span>
          <v-spacer />
          <Button
            :elevation="0"
            @click="dialogClose"
          >
            <v-icon>mdi-close</v-icon>
          </Button>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
              >
                Tip
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                :complete="e1 > 2"
                step="2"
              >
                İnfo
              </v-stepper-step>

              <v-divider />
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <div class="pt-4">
                  <v-col
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <Select
                      v-model="santralModel"

                      :items="santralList"
                      label="Santral Listesi"
                      :outlined="true"
                      :dense="true"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <Select
                      v-if="santralModel"
                      v-model="dataloggerModel"

                      :items="dataloggerList"
                      label="Cihaz Listesi"
                      :outlined="true"
                      :dense="true"
                    />
                  </v-col>

                  <v-col
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <Select
                      v-if="dataloggerModel"
                      v-model="connectTypeModel"

                      :items="connectType"
                      label="Baglantı Tipi"
                      :outlined="true"
                      :dense="true"
                    />
                  </v-col>
                  <v-col class="d-flex mt-5">
                    <Button
                      color="primary"
                      :dense="true"
                      :outlined="true"
                      class="mr-4"
                      @click="nexTab"
                    >
                      Devam
                    </Button>

                    <Button
                      color="primary"
                      :dense="true"
                      :text="true"
                      :outlined="true"
                      :hide-details="true"
                      @click="dialogClose"
                    >
                      Kapat
                    </Button>
                  </v-col>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div class="mb-3 mt-3 ml-1 mr-1">
                  <component
                    :is="connectTypeModel"
                    ref="formData"
                    :device-data="formData"
                    :connect-type="connectTypeModel"
                    :bind="true"
                    @savedataloger="savedatalogerlFunc"
                  />

                  <v-col class="d-flex">
                    <Button
                      color="primary"
                      :dense="true"
                      :text="true"
                      :outlined="true"
                      class="mr-4"
                      @click="e1 = 1"
                    >
                      Önceki
                    </Button>

                    <Button
                      color="primary"
                      :dense="true"
                      :text="true"
                      :outlined="true"
                      @click="dialogClose"
                    >
                      Kapat
                    </Button>
                  </v-col>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </Card>
    </Dialog>
    <Snackbar
      :notify="notify"
    />
  </v-container>
</template>
<script>
import { getDeviceList } from '@/api/Request/farm';

import {
  setDataloggerCreate,
  setDataloggerDelete,
  getDataloggerList,
  setDataloggerEdit,
} from '@/api/Request/datalogger';

import { getSantralList } from '@/api/Request/santral';

import Ftp from '@/views/components/Datalogger/Ftp.vue';
import Http from '@/views/components/Datalogger/Http.vue';
import Modbus from '@/views/components/Datalogger/Modbus.vue';
import API from '@/views/components/Datalogger/API.vue';
import helper from '@/api/helper';

const dataloggerTypeEnum = {
  Sunguard1: {
    url: '/nqzva__PRX__nqzva/snmp.med',
  },
  Sunguard2: {
    url: '/?page=display&all',
  },
};
export default {
  components: {
    Ftp, Http, Modbus, API,
  },
  data: () => ({
    devdesserts: [],
    itemPerPage: 15,
    dialog: false,
    search: '',
    valid: true,
    lazy: true,
    edit: false,
    editdialog: false,
    e1: '1',
    prefix: '',
    companyCode: '',
    santralCode: '',
    connectTypeModel: 'Ftp',
    dataloggerModel: '',
    dataloggerList: [],
    dataloggerData: {},
    formData: {},
    farmInfo: {},
    santralData: {},
    santralList: [],
    santralModel: null,
    santralModel2: null,
    notify: {
      snackbar: false,
      text: '',
      vertical: true,
      color: 'success',
    },
  }),
  computed: {
    connectType() {
      return [
        { text: 'Ftp', value: 'Ftp' },
        { text: 'Modbus', value: 'Modbus' },
        { text: 'Http', value: 'Http' },
        { text: 'API', value: 'API' },
      ];
    },
    headers() {
      return [
        { text: 'Santral ', value: 'santral' },
        { text: 'Cihaz', value: 'deviceName' },
        { text: 'Bağlantı Tip', value: 'connectType' },
        { text: 'Üretici', value: 'DeviceManufacture' },
        { text: 'Model', value: 'DeviceModel' },
        { text: 'Durum', value: 'status' },
        { text: '', value: 'santralCode' },
      ];
    },
  },
  watch: {
    dataloggerModel() {
      if (helper.is_Empty(this.dataloggerModel)) {
        return;
      }
      this.formData.companyCode = this.companyCode;
      this.formData.prefix = this.prefix;
      this.formData.santralCode = this.santralModel;
      this.formData.santral = this.santralData[this.santralModel].santral;

      // dataloggerTypeEnum

      this.formData = {
        ...this.formData,
        ...this.dataloggerData[this.dataloggerModel],
      };

      if (this.dataloggerModel in dataloggerTypeEnum) {
        this.formData = {
          ...this.formData,
          ...dataloggerTypeEnum[this.dataloggerModel],
        };
      }
    },
    santralModel2() {
      this.getDatalogger();
    },
    santralModel() {
      this.getSantralInfo();
    },
    dialog() {
      if (this.dialog === false) {
        this.formData = {};
      }
    },
  },
  created() {},
  mounted() {
    this.selectedFarmFunc();
  },
  methods: {
    nexTab() {
      if (!helper.is_Empty(this.dataloggerModel)) {
        this.e1 = 2;
      }
    },
    selectedFarmFunc() {
      const storage = window.localStorage;
      const userCompany = JSON.parse(window.atob(storage.getItem('user_company')));
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;

      this.farmInfo = {
        companyCode: this.companyCode,
        prefix: this.prefix,
      };
      this.getDatalogger();
      this.getSantralList();
      this.getDevice();
      this.getSantralInfo();
    },
    dialogClose() {
      this.santralModel = '';
      this.dataloggerModel = '';
      this.dialog = false;
      this.e1 = '1';
      this.getDatalogger();
    },
    editItemOpen(item) {
      this.santralModel = item.santralCode;
      this.dataloggerModel = item.Model;
      this.connectTypeModel = item.connectType;
      this.formData = item;
      this.dialog = true;
    },

    getSantralList() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
      };
      this.santralList = [];
      // console.log(params);
      getSantralList(params).then((res) => {
        const desserts = [];
        this.santralList = [];

        desserts.push({
          text: 'Seçiniz',
          value: null,
        });

        if (helper.resIsEmpty(res)) {
          Object.keys(res.data.data).forEach((k) => {
            desserts.push({
              text: res.data.data[k].santral,
              value: res.data.data[k].santralCode,
            });
          });

          this.santralList = desserts;
        }
      });
    },
    savedatalogerlFunc(item) {
      if (item.id !== undefined) {
        const params = {
          condiniton: {
            id: item.id,
          },
          data: { ...this.santralData[this.santralModel], ...item },
        };
        params.condiniton.id = item.id;
        setDataloggerEdit(params).then(() => {
        });
      } else {
        const params = {
          condiniton: {
            prefix: this.prefix,
            santralCode: this.santralData[this.santralModel].santralCode,
            santral: this.santralData[this.santralModel].santral,
          },
          data: { ...this.santralData[this.santralModel], ...item },
        };
        params.condiniton.deviceName = item.deviceName;
        setDataloggerCreate(params).then((res) => {
          if (res.data.success === 'false') {
            this.notify.snackbar = true;
            this.notify.text = res.data.message;
            this.notify.vertical = true;
            this.notify.color = 'red';
          }
          if (res.data.success === 'true') {
            this.notify.snackbar = true;
            this.notify.text = res.data.message;
            this.notify.vertical = true;
            this.notify.color = 'success';
          }
        });
      }
    },
    createDevice() {
      this.dialog = true;
    },
    santralAdd(item) {
      this.deviceModel = item.dataLoggerType;
    },
    getSantralInfo() {
      const params = {
        condiniton: {
          prefix: this.prefix,
        },
      };
      this.santralData = {};

      getSantralList(params).then((res) => {
        const santralData = {};

        if (res.data.success === 'true') {
          if (res.data.data.length > 0) {
            Object.keys(res.data.data).forEach((i) => {
              if (helper.resIsEmpty(res)) {
                // eslint-disable-next-line no-param-reassign
                delete res.data.data[i].id;
                santralData[res.data.data[i].santralCode] = res.data.data[i];
              }
            });

            this.santralData = santralData;
          }
        }
      });
    },

    // Datalogger listesi çek
    getDevice() {
      const params = {
        condiniton: {
          DeviceType: 'Datalogger',
        },
      };
      this.dataloggerList = [];
      this.dataloggerData = [];
      getDeviceList(params).then((res) => {
        const devList = [];
        const deviceData = {};
        Object.keys(res.data.data).forEach((i) => {
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].Model,
          });

          deviceData[res.data.data[i].Model] = res.data.data[i];
        });

        this.dataloggerList = devList;
        this.dataloggerData = deviceData;
      });
    },
    deleteItem(item) {
      const params = {
        condiniton: {
          id: item.id,
          prefix: this.prefix,
        },
      };
      setDataloggerDelete(params).then(() => {
        setTimeout(() => {
          this.selectedFarmFunc();
        }, 500);
      });
    },
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
        },
      };

      if (!helper.is_Empty(this.santralModel2)) {
        params.condiniton.santralCode = this.santralModel2;
      }
      this.devdesserts = [];
      getDataloggerList(params).then((res) => {
        if (helper.resIsEmpty(res)) {
          this.devdesserts = res.data.data;
        }
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
