<template>
  <div>
    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0 pt-0 pb-0">
        <v-col
          cols="12"
          md="4"
          class="mb-0 mt-0 pt-0 pb-0"
        >
          <!--Cihaz Adı:-->
          <TextField
            v-model="formData.deviceName"
            label="Cihaz Adı:"
            :required="true"
            :dense="true"
            :autofocus="true"
            :outlined="true"
            :hide-details="true"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="mb-0 mt-0 pt-0 pb-0"
        >
          <!--Cihaz Seri Numarası:-->
          <TextField
            v-model="formData.serial"
            label="Cihaz Seri Numarası:"
            :dense="true"
            :autofocus="true"
            :outlined="true"
            :hide-details="true"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="mb-0 mt-0 pt-0 pb-0"
        >
          <!--Zaman Aralığı:-->

          <Select
            v-model="timeInterval"
            label="Zaman Aralığı:"
            :items="timeIntervalList"
            :dense="true"
            :autofocus="true"
            :outlined="true"
            :hide-details="true"
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-6 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0 pt-0 pb-0">
        <v-col
          cols="12"
          md="4"
          class="mb-0 mt-0 pt-0 pb-0"
        >
          <!--Datalogger Kullanıcı adı:-->
          <TextField
            v-model="formData.LoggerName"
            label="Datalogger Kullanıcı adı:"
            :dense="true"
            :required="true"
            :autofocus="true"
            :outlined="true"
            :hide-details="true"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="mb-0 mt-0 pt-0 pb-0"
        >
          <!--Datalogger Şifre:-->
          <TextField
            v-model="formData.LoggerPass"
            label="Datalogger Şifre:"
            :dense="true"
            :autofocus="true"
            :outlined="true"
            :required="true"
            :hide-details="false"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="mb-0 mt-0 pt-0 pb-0"
        >
          <TextField
            v-model="formData.ipAddress"
            label="Ip adres:"
            :required="true"
            :dense="true"
            :autofocus="true"
            :outlined="true"
            :hide-details="true"
          />
        </v-col>
      </v-col>
    </v-row>
    <v-row class="mb-0 mt-0 pt-0 pb-0 pr-6">
      <v-col
        cols="12"
        md="4"
        class="mb-0 mt-0 pt-0 pb-0 ml-3"
      >
        <TextField
          v-model="formData.port"
          label="Port:"
          :required="true"
          :dense="true"
          :autofocus="true"
          :outlined="true"
          :hide-details="true"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
        class="mb-0 mt-0 pt-0 pb-0"
      >
        <!--Durum:-->
        <Select
          v-model="formData.status"
          label="Durum:"
          :items="status"
          :dense="true"
          :autofocus="true"
          :outlined="true"
          :hide-details="true"
        />
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col
        cols="12"
        md="12"
        class="text-right"
      >
        <Button
          color="primary"
          :dense="true"
          :text="true"
          :outlined="true"
          @click="saveForm"
        >
          Cihazı Kaydet
        </Button>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import helper from '@/api/helper';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['deviceData', 'connectType'],
  data: () => ({
    formData: {},
    type: 'Http',
    timeIntervalList: [],
    dateFormaterList: [
      { text: 'Y-m-d H:M:S', value: '%Y-%m-%d %H:%M:%S' },
      { text: 'd-m-Y H:M:S', value: '%m-%m-%Y %H:%M:%S' },
    ],
    fileFormaterList: [
      { text: 'YmdH', value: '%Y%m%d%H' },
      { text: 'dmYH', value: '%d%m%Y%H' },
    ],
    timeInterval: '5',
  }),
  computed: {
    status() {
      return [
        { text: 'Aktif', value: 'Active' },
        { text: 'Pasif', value: 'Passive' },
      ];
    },
  },
  watch: {
    deviceData() {
      this.setForm();
    },
  },
  mounted() {
    this.setForm();
  },
  methods: {
    setEmptyForm() {
      this.formData = {
        deviceName: '',
        ipAddress: '',
        port: '',
        serial: '',
        dateFormaterModel: '',
        Model: '',
        status: 'Active',
        timeInterval: '',
        url: '',
        ftpfile: '',
        ftppass: '',
        ftpuser: '',
        ftpfilename: '',
        ftpHost: '',
        dataloggerCode: '',
        apiKey: '',
        connectType: this.connectType,
      };
    },
    setForm() {
      this.setEmptyForm();
      this.timeInterval = '5';
      this.formData = { ...this.formData, ...this.deviceData };
      if ('timeInterval' in this.formData) {
        this.timeInterval = this.formData.timeInterval.toString();
      }

      const dataloggerCode = helper.randomChars(10);

      if (!helper.is_Empty(this.formData.dataloggerCode)) {
        this.formData.dataloggerCode = this.deviceData.dataloggerCode;
      } else {
        this.formData.dataloggerCode = dataloggerCode;
      }

      const timeIntervalList = [];
      for (let i = 5; i <= 60; i += 5) {
        const iv = i.toString();
        timeIntervalList.push(iv);
      }

      this.timeIntervalList = timeIntervalList;
    },

    saveForm() {
      const compy = { ...{}, ...{ timeInterval: this.timeInterval } };
      this.formData.timeInterval = compy.timeInterval;

      this.$emit('savedataloger', this.formData);
    },
  },
};
</script>

<style></style>
